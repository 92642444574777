import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";
import UnknownProfilePhoto from "../../../../assets/images/default-profile.jpg";
import {
  combineArrayToStr,
  instrumentsToString,
} from "../../../../utils/helpers";
import CustomTooltip from "../../common/components/CustomTooltip";
import { renderToStaticMarkup } from "react-dom/server";

const ImageWrapper = styled.div`
  text-align: center;
`;
const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;
const TeacherName = styled.p`
  color: #000;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
`;
const InfoText = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
`;

const TeacherCard = ({
  teacher,
  studios = {},
  currentDate,
  selectedTeachersStudioUsages,
}) => {
  const { fullName, imageUrl, instrumentsInfo } = teacher || {};
  const teacherInstrumentsNames = instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teacherId = teacher?.id || teacher?.uid;

  const teacherStudioUsageInCurrentDate = selectedTeachersStudioUsages?.find(
    (studioUsage) => {
      const isSameTeacher = teacherId === studioUsage.teacher_id;
      const isSameDate =
        moment(currentDate).year() === studioUsage.year &&
        moment(currentDate).month() + 1 === studioUsage.month &&
        moment(currentDate).format("dddd") === studioUsage.weekday;

      return isSameTeacher && isSameDate;
    }
  );
  const studioOnCurrentDate =
    studios[teacherStudioUsageInCurrentDate?.studio_id];
  const studioName = studioOnCurrentDate?.name;

  const tooltipContent = (
    <ul className="m-0 p-0" style={{ listStyle: "none" }}>
      {teacher.teacherDays?.availableDays?.map((scheduleDay) => {
        const { startDate, endDate, isVirtualOnly } = scheduleDay;
        const dayName = moment(startDate).format("dddd");
        const start = moment(startDate).format("hh:mm A");
        const end = moment(endDate).format("hh:mm A");
        const virtualStatus = isVirtualOnly ? "(Virtual Only)" : "";
        const dayStr = `${dayName}: ${start} - ${end} ${virtualStatus}`;
        return <li className="mb-2">{dayStr}</li>;
      })}
    </ul>
  );

  return (
    <div className="mt-2" style={{ minHeight: "200px" }}>
      <ImageWrapper>
        <Image width={100} height={100} src={imageUrl || UnknownProfilePhoto} />
      </ImageWrapper>
      <div
        className="teacher-card-anchor-element"
        data-tooltip-html={renderToStaticMarkup(tooltipContent)}
      >
        <TeacherName>{fullName || "N/A"}</TeacherName>
        <InfoText>
          {instrumentsToString(teacherInstrumentsNames) || ""}
          <br />
          {studioName || ""}
        </InfoText>
      </div>
      <CustomTooltip
        anchorSelect=".teacher-card-anchor-element"
        place="bottom"
        variant="warning"
        style={{
          backgroundColor: "#037ec2",
          fontSize: 18,
        }}
      />
    </div>
  );
};

export default TeacherCard;
