import violaIcon from "./icons/violaIcon.png";
import { GiGrandPiano } from "react-icons/gi";
import { MdKeyboardVoice } from "react-icons/md";
import { GiFlute } from "react-icons/gi";
import { BsFileEarmarkMusic } from "react-icons/bs";
import { GiViolin } from "react-icons/gi";
import { GiClarinet } from "react-icons/gi";
import { LiaGuitarSolid } from "react-icons/lia";
import { GiGuitar } from "react-icons/gi";
import { GiDrum } from "react-icons/gi";
import { GiMeditation } from "react-icons/gi";
import { GiSaxophone } from "react-icons/gi";
import "./style.css";
import IconWrapper from "./IconWrapper";
import { allIcons } from "src/components/pages/OurTeam/staffInfo";

const icons = [
  <>
    Piano <GiGrandPiano className="privateInstructionsIcons" />
  </>,
  <>
    Voice <MdKeyboardVoice className="privateInstructionsIcons" />
  </>,
  <>
    Flute <GiFlute className="privateInstructionsIcons" />
  </>,
  <>
    Music Theory <BsFileEarmarkMusic className="privateInstructionsIcons" />
  </>,
  <>
    Violin <GiViolin className="privateInstructionsIcons" />
  </>,
  <>
    Viola <img scr={violaIcon} alt="viola icon" />
  </>,
  <>
    Guitar <GiGuitar className="privateInstructionsIcons" />
  </>,
  <>
    ukulele <LiaGuitarSolid className="privateInstructionsIcons" />
  </>,
  <>
    Drum <GiDrum className="privateInstructionsIcons" />
  </>,
  <>
    Clarinet <GiClarinet className="privateInstructionsIcons" />
  </>,
  <>
    Saxophone <GiSaxophone className="privateInstructionsIcons" />
  </>,
  <>
    Meditation <GiMeditation className="privateInstructionsIcons" />
  </>,
];

const IconsTable = () => {
  return (
    <>
      <div className="iconsWrapper">
        {allIcons.map((icon, index) => (
          <IconWrapper key={index} name={icon.name}>
            {icon.name}
            {<icon.Icon className="privateInstructionsIcons" />}
          </IconWrapper>
        ))}
      </div>
      {/* <div className="iconsWrapper">
        {icons.map((icon, index) => (
          <IconWrapper key={index}>{icon}</IconWrapper>
        ))}
      </div> */}
    </>
  );
};

export default IconsTable;
