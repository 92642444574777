import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import PrivateLessonModificationModal from "../../AdminSchedule/modals/PrivateLessonModificationModal";
import useModal from "src/hooks/useModal";
import { hasAdminCredentials, UserRole } from "src/constants/UserRoleEnum";
import {
  isSingleTimeCalendarLabelEvent,
  isOneTimeBreakEvent,
  isRecurringBreakEvent,
  isRecurringCalendarLabelEvent,
  isPackagePrivateLesson,
  isPrivateLessonEvent,
  isAppointmentEvent,
  isTrialLessonEvent,
  isMakeupLessonEvent,
  isGroupClassEvent,
} from "src/constants/eventsEnum";
import CalendarLabelEventContent from "./CalendarLabelEventContent";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import AppointmentEventContent from "./AppointmentEventContent";
import PrivateLessonEventContent from "./PrivateLessonEventContent";
import TrialLessonEventContent from "./TrialLessonEventContent";
import MakeupLessonEventContent from "./MakeupLessonEventContent";
import TrialLessonEditModal from "../../AdminSchedule/modals/TrialLessonEditModal";
import MakeupLessonEditModal from "../../AdminSchedule/modals/MakeupLessonEditModal";
import PackageLessonEditModal from "../../AdminSchedule/modals/PackageLessonEditModal";
import GroupClassEventContent from "./GroupClassEventContent";

const CustomModal = styled(Modal)`
  min-width: 650px;
  max-width: 650px;
  & .modal-content {
    border: none;
    background: transparent;
  }

  @media (max-width: ${ScreenSize.MD}px) {
    min-width: 95%;
    max-width: 95%;
  }
`;
const CustomHeader = styled(ModalHeader)`
  background-color: #681e46;
  border-radius: 30px 30px 0 0;
`;
const CustomBody = styled(ModalBody)`
  padding: 0 !important;
`;
const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

const BodyInnerWrapper = styled.div`
  background-color: #fff;

  border-radius: 0 0 30px 30px;
`;
const CurveContainer = styled.div`
  /* background-color: #681e46; */
  background-color: #fff;
`;
const EventDetailsModal = ({
  modalData,
  event,
  initialData,
  refreshData,
  userRole,
  studioUsages,
}) => {
  console.log({ event });

  const editPLModalData = useModal();
  const editPackageLessonModalData = useModal();
  const editTrialLessonModalData = useModal();
  const editMakeupLessonModalData = useModal();

  const isSingleTimeLabelEvent = isSingleTimeCalendarLabelEvent(
    event.eventCode
  );
  const isRecurringLabelEvent = isRecurringCalendarLabelEvent(event.eventCode);
  const isAppointment = isAppointmentEvent(event.eventCode);
  const isPrivateLesson = isPrivateLessonEvent(event.eventCode);
  const isTrialLesson = isTrialLessonEvent(event.eventCode);
  const isMakeupLesson = isMakeupLessonEvent(event.eventCode);
  const isGroupClass = isGroupClassEvent(event.eventCode);

  const isLabelEvent = isSingleTimeLabelEvent || isRecurringLabelEvent;

  const hasAdminsPermissions = hasAdminCredentials(userRole);

  const renderCurrentModalData = () => {
    if (isPrivateLesson) {
      return (
        <>
          <PrivateLessonEventContent
            event={event}
            initialData={initialData}
            openModificationModal={editPLModalData.openModal}
            openPackageLessonEditModal={editPackageLessonModalData.openModal}
            closeEventDetailsModal={modalData.closeModal}
            userRole={userRole}
            refreshData={refreshData}
            studioUsages={studioUsages}
          />
          {hasAdminsPermissions &&
            isPrivateLesson &&
            !isPackagePrivateLesson(event.type) && (
              <PrivateLessonModificationModal
                modalData={editPLModalData}
                closeDetailsModal={modalData.closeModal}
                initialData={initialData}
                event={event}
                refreshData={refreshData}
              />
            )}
          {hasAdminsPermissions &&
            isPrivateLesson &&
            isPackagePrivateLesson(event.type) && (
              <PackageLessonEditModal
                modalData={editPackageLessonModalData}
                event={event}
                refreshData={refreshData}
                closeDetailsModal={modalData.closeModal}
              />
            )}
        </>
      );
    } else if (isTrialLesson) {
      return (
        <>
          <TrialLessonEventContent
            event={event}
            initialData={initialData}
            studioUsages={studioUsages}
            openEditModal={editTrialLessonModalData.openModal}
            userRole={userRole}
          />
          {hasAdminsPermissions && isTrialLesson && (
            <TrialLessonEditModal
              modalData={editTrialLessonModalData}
              event={event}
              refreshData={refreshData}
              closeDetailsModal={modalData.closeModal}
            />
          )}
        </>
      );
    } else if (isMakeupLesson) {
      return (
        <>
          <MakeupLessonEventContent
            event={event}
            initialData={initialData}
            studioUsages={studioUsages}
            openEditModal={editMakeupLessonModalData.openModal}
            userRole={userRole}
          />
          {hasAdminsPermissions && isMakeupLesson && (
            <MakeupLessonEditModal
              modalData={editMakeupLessonModalData}
              event={event}
              refreshData={refreshData}
              closeDetailsModal={modalData.closeModal}
            />
          )}
        </>
      );
    } else if (isLabelEvent) {
      return (
        <CalendarLabelEventContent
          event={event}
          closeEventDetailsModal={modalData.closeModal}
          refreshData={refreshData}
        />
      );
    } else if (isAppointment) {
      return (
        <AppointmentEventContent
          event={event}
          initialData={initialData}
          closeEventDetailsModal={modalData.closeModal}
          refreshData={refreshData}
          studioUsages={studioUsages}
        />
      );
    } else if (isGroupClass) {
      return <GroupClassEventContent event={event} />;
    } else {
      return <div></div>;
    }
  };

  return (
    <div>
      <CustomModal
        size="md"
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        >
          {/* <span className="fs-3 fw-bold">Event</span> */}
        </CustomHeader>
        <CustomBody>
          <CurveContainer>
            <svg
              width="100%"
              height="100%"
              id="svg"
              viewBox="0 0 1440 300"
              xmlns="http://www.w3.org/2000/svg"
              class="transition duration-300 ease-in-out delay-150"
            >
              <path
                d="M 0,400 C 0,400 0,200 0,200 C 107.46666666666664,243.60000000000002 214.93333333333328,287.20000000000005 400,271 C 585.0666666666667,254.79999999999998 847.7333333333333,178.79999999999998 1034,157 C 1220.2666666666667,135.20000000000002 1330.1333333333332,167.60000000000002 1440,200 C 1440,200 1440,400 1440,400 Z"
                stroke="none"
                stroke-width="0"
                fill="#681e46"
                class="transition-all duration-300 ease-in-out delay-150 path-0"
                transform="rotate(-180 720 200)"
              ></path>
            </svg>
          </CurveContainer>
          <BodyInnerWrapper>{renderCurrentModalData()}</BodyInnerWrapper>
        </CustomBody>
      </CustomModal>
    </div>
  );
};

export default EventDetailsModal;
