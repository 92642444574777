/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState, createContext } from "react";
import { Container, Table, Col, FormGroup, Input, Row } from "reactstrap";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";
import styled from "styled-components";
import { FirebaseContext } from "../../Firebase";
import { UserRole } from "../../../constants/UserRoleEnum";
import StudentsSearch from "./StudentsSearch";
import { InfoText } from "../../../utils/styled";
import RouteContent from "../../common/RouteContent";
import {
  getFirebaseDoc,
  parseFirebaseDoc,
} from "../../../utils/getFirebaseDoc";
import StudentsView from "./StudentsView";
import StudentsSort from "./StudentsSort";
import LocationSelect from "../../common/LocationSelect.js";

// import moment from "moment";
// import {
//   generateLessonTimesByDurations,
//   getWeekDays,
//   isTeacherAvailableThisDay,
// } from "../../../utils/PrivateLessons.utils";
import { getDocs, query, where } from "firebase/firestore";
import "./style.css";
import {
  filterLessonsByLocation,
  filterStudentsByLessons,
  getUncompletePrivateStudents,
} from "../../../utils/helpers";
import { useMemo } from "react";
import { PrimaryButton } from "src/utils/shared/styled";
import AddPrivateStudentModal from "../common/components/AddPrivateStudentModal";
export const PrivateLessonsContext = createContext({});
const SearchandAddContainer = styled.div`
  display: flex;
  width: 98%;
`;
const Searchicon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;

const CustomSearchInput = styled.input`
  &::-webkit-search-cancel-button {
    position: relative;
    right: 30px;
  }
`;
const PrivateLessons = () => {
  const firebase = useContext(FirebaseContext);
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [lessons, setLessons] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchUser, setsearchUser] = useState("");
  const [filterdUsers, setfilterdUsers] = useState([]);
  const [addStudentModalOpen, setAddStudentModalOpen] = useState(false);
  const [isToSortArr, setisToSortArr] = useState(false);
  const [SortedStudents, setSortedStudents] = useState();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);

  const [showIncompleteStudents, setShowIncompleteStudents] = useState(false);

  const filteredLessons = filterLessonsByLocation(lessons, selectedLocations);
  const studentsFilteredByLessons = useMemo(
    () => filterStudentsByLessons(students, filteredLessons),
    [students, filteredLessons]
  );
  const incompleteStudents = useMemo(
    () => getUncompletePrivateStudents(students, lessons),
    [students, lessons]
  );
  // if showIncompleteStudents is enabled, we only show incomplete students (students with no PLs), else we show students that have PLs
  const filteredStudents = showIncompleteStudents
    ? incompleteStudents
    : studentsFilteredByLessons;

  const toggleShowIncompleteStudents = () => {
    setShowIncompleteStudents((oldVal) => !oldVal);
    return;
  };
  const fetchLessons = async () => {
    // const lessonsDocs = await firebase.lessons().get();
    const lessonsDocs = await getDocs(firebase.PrivateLessons());
    const lessons = getFirebaseDoc(lessonsDocs);
    setLessons(lessons);
  };
  const fetchStudentsAndParents = async () => {
    // const usersDocs = await firebase.users().get();
    const usersDocs = await getDocs(firebase.users());
    const students = usersDocs.docs
      .map((user) => ({
        id: user.id,
        ...user.data(),
      }))
      .filter((user) => user.role === UserRole.STUDENT);
    const parents = usersDocs.docs
      .map((user) => ({
        id: user.id,
        ...user.data(),
      }))
      .filter((user) => user.role === UserRole.PARENT);
    setStudents(students);
    setParents(parents);
  };
  const fetchunSortedStudents = async () => {
    const usersDocs = await getDocs(firebase.users());
    const students = usersDocs.docs
      .map((user) => ({
        id: user.id,
        ...user.data(),
      }))
      .filter((user) => user.role == UserRole.STUDENT);
    setSortedStudents(students);
  };
  const fetchInstruments = async () => {
    const instrumentsDocs = await getDocs(firebase.instruments());
    const instruments = getFirebaseDoc(instrumentsDocs);
    setInstruments(instruments);
  };
  const fetchPrograms = async () => {
    const programsDocs = await getDocs(firebase.programs());
    const programs = getFirebaseDoc(programsDocs);
    setPrograms(programs);
  };

  const fetchTeachers = async () => {
    const q = query(firebase.users(), where("role", "==", UserRole.TEACHER));
    const teachersDocs = await getDocs(q);
    const teachers = getFirebaseDoc(teachersDocs);
    setTeachers(teachers);
  };
  const fetchLocations = async () => {
    const locationsDocs = await getDocs(firebase.getLocations());
    const addedLocations = locationsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const locationsIds = addedLocations.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    setLocations(addedLocations);
    setSelectedLocations(locationsIds);
  };
  const fetchSchoolYears = async () => {
    const schoolYearsSnap = await getDocs(firebase.getSchoolYears());
    const schoolYears = parseFirebaseDoc(schoolYearsSnap.docs);
    setSchoolYears(schoolYears);
  };
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await Promise.all([
        fetchStudentsAndParents(),
        fetchunSortedStudents(),
        fetchInstruments(),
        fetchPrograms(),
        fetchTeachers(),
        fetchLessons(),
        fetchLocations(),
        fetchSchoolYears(),
      ]);
      setLoading(false);
    };
    load();
  }, []);
  useEffect(() => {
    const load = async () => {
      await Promise.all([
        fetchLessons(),
        fetchStudentsAndParents(),
        fetchunSortedStudents(),
      ]);
    };
    load();
  }, [loading]);
  // search user logic functions
  const handleChange = (e) => {
    e.preventDefault();
    setsearchUser(e.target.value);
  };
  useEffect(() => {
    const StudentsFilteredList = filteredStudents.filter((student) => {
      if (searchUser == null || searchUser === "" || searchUser === " ") {
        return false;
      } else {
        const targetStudent = student.fullName
          ? student.fullName.toLowerCase().includes(searchUser?.toLowerCase())
          : null;
        return targetStudent;
      }
    });
    setfilterdUsers([...StudentsFilteredList]);
  }, [searchUser, loading, students]);
  // search user logic functions
  if (loading) return null;
  const HandleSort = () => {
    setisToSortArr(true);
  };
  const onSelectLocation = (locationsIds) => {
    setSelectedLocations(locationsIds);
  };

  return (
    <>
      <RouteContent title="Private Students"></RouteContent>
      <SearchandAddContainer className="mt-5 mx-3 flex-lg-row justify-content-between align-items-center">
        <div>
          <Col className="align-items-center d-flex">
            <InfoText
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAddStudentModalOpen(true);
                var x = document.getElementsByTagName("BODY")[0];
                x.classList.add("overflowBody");
              }}
            >
              <i
                style={{ color: "black", marginTop: "7px" }}
                className="fas fa-plus mx-2"
              />
              Add New Student
            </InfoText>
          </Col>
        </div>
        <div className="w-50">
          <Col>
            <FormGroup className="searchContainer">
              <CustomSearchInput
                placeholder="Search by Student"
                className="searchInput"
                value={searchUser}
                onChange={(e) => handleChange(e)}
                type="search"
              />
              <Searchicon />
            </FormGroup>
          </Col>
        </div>
        <div>
          <Col>
            <div>
              <LocationSelect
                // width="350px"
                selectedLocations={selectedLocations}
                locations={locations}
                onSelectLocation={onSelectLocation}
              />
            </div>
          </Col>
        </div>
      </SearchandAddContainer>
      <Row>
        <Col>
          <div className="mt-3 mb-3 text-end">
            <PrimaryButton
              width={"332px"}
              onClick={toggleShowIncompleteStudents}
            >
              {showIncompleteStudents
                ? "Show Private Students"
                : "Show Incomplete Students"}
            </PrimaryButton>
          </div>
        </Col>
      </Row>
      <Container fluid>
        <PrivateLessonsContext.Provider
          value={{
            instruments,
            programs,
            teachers,
            students: filteredStudents,
            showIncompleteStudents,
            parents,
            SortedStudents,
            // lessons: filteredLessons,
            lessons,
            locations,
            updateStudents: setStudents,
            updateLessons: setLessons,
            setloading: setLoading,
            loading,
            schoolYears,
          }}
        >
          {searchUser ? (
            <StudentsSearch
              searchUser={searchUser}
              filterdUsers={filterdUsers}
            />
          ) : isToSortArr ? (
            <StudentsSort
              IsToSortArr={isToSortArr}
              SetisToSortArr={setisToSortArr}
            />
          ) : (
            <StudentsView
              itemsPerPage={15}
              SetisToSortArr={setisToSortArr}
              HandleSort={HandleSort}
            />
          )}
          <AddPrivateStudentModal
            isOpen={addStudentModalOpen}
            onAddStudentSuccess={(student) => {
              setStudents([...students, student]);
              setAddStudentModalOpen(false);
            }}
            onClose={() => setAddStudentModalOpen(false)}
            instruments={Object.values(instruments) || []}
            locations={locations || []}
            programs={Object.values(programs) || []}
            teachers={Object.values(teachers) || []}
          />
        </PrivateLessonsContext.Provider>
      </Container>
    </>
  );
};
export default PrivateLessons;
