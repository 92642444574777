import moment from "moment";
import UnknownProfilePhoto from "../../../../../../assets/images/default-profile.jpg";
import {
  availableDaysToString,
  combineArrayToStr,
  getTimeDiffInMins,
  instrumentsToString,
} from "../../../../../../utils/helpers";
import {
  eventsMap,
  isPackagePrivateLesson,
  isPrivateLessonEvent,
  isTrialLessonEvent,
} from "../../../../../../constants/eventsEnum";
import { PrimaryButton } from "../../../../../../utils/shared/styled";
import { isStudentAbleToRequestAbsence } from "../../helperFns";
import { Header, ImageContainer, InfoText, ProfileImage } from "./styled";

const EventContent = ({ event, initialData, openAbsenceModal }) => {
  const { instruments, locations, studios } = initialData || {};
  const {
    student,
    instrumentId,
    modifiedInstrumentId,
    start,
    end,
    eventCode,
    currentTimeline,
    type,
  } = event || {};
  const teacher = event.teacher || currentTimeline?.teacher;
  const instrument = modifiedInstrumentId
    ? instruments[modifiedInstrumentId]
    : instruments[instrumentId] || instruments[event.instrument];

  const isTrialLesson = isTrialLessonEvent(eventCode);
  const isPackagePL =
    isPrivateLessonEvent(eventCode) && isPackagePrivateLesson(type);
  const isRegularPL =
    isPrivateLessonEvent(eventCode) && !isPackagePrivateLesson(type);

  const locationName = isTrialLesson
    ? combineArrayToStr(
        event.locations?.map((locationId) => locations[locationId]?.name)
      )
    : isRegularPL
    ? locations[currentTimeline?.locationId]?.name
    : locations[event.locationId]?.name;

  const studentName = isTrialLesson ? student?.name : student?.fullName;

  const formattedDate =
    moment(start).format("dddd, MMMM D: h:mm a - ") +
    moment(end).format("h:mm a");
  const eventDay = moment(start).format("dddd");
  const teachingDaysStr = availableDaysToString(
    teacher?.teacherDays?.availableDays
  );
  const teacherInstrumentsNames = teacher?.instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teachersInstrumentsStr = instrumentsToString(teacherInstrumentsNames);

  if (!isPrivateLessonEvent(eventCode) || isTrialLesson) {
    return <div style={{ height: "200px" }}></div>;
  }

  return (
    <div className="py-4">
      <div className="d-flex mb-3">
        <ImageContainer>
          <ProfileImage src={student?.imageUrl || UnknownProfilePhoto} />
        </ImageContainer>
        <div>
          <Header>
            {studentName} {`(${instrument?.name})`}
          </Header>
          <InfoText>{formattedDate}</InfoText>
          {isRegularPL && <InfoText>Weekly on {eventDay}</InfoText>}
        </div>
      </div>
      <div className="d-flex">
        <ImageContainer>
          <ProfileImage src={teacher?.imageUrl || UnknownProfilePhoto} />
        </ImageContainer>
        <div>
          <Header>
            {teacher?.fullName}{" "}
            {`(${teachingDaysStr}) (${teachersInstrumentsStr})`}
          </Header>
          <InfoText>{locationName || "No Location found"}</InfoText>
        </div>
      </div>
      {isStudentAbleToRequestAbsence(event) ? (
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <PrimaryButton onClick={openAbsenceModal} padding="9px">
            Absence Request
          </PrimaryButton>
        </div>
      ) : null}
    </div>
  );
};

export default EventContent;
