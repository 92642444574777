import React from "react";
import styled from "styled-components";
import { Notepad } from "@styled-icons/boxicons-regular/Notepad";
import { CustomTextarea } from "src/utils/shared/styled";
import { Header, ImageContainer, InfoText, ProfileImage } from "../styled";
import UnknownProfilePhoto from "../../../../../../assets/images/default-profile.jpg";
import {
  availableDaysToString,
  injectUserStore,
  instrumentsToString,
} from "src/utils/helpers";
import moment from "moment";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import useAppointmentEvent from "./hooks/useAppointmentEvent";
import { hasAdminCredentials } from "src/constants/UserRoleEnum";
import { toJS } from "mobx";

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 35px;
`;

const AppointmentEventContent = ({
  event,
  initialData,
  closeEventDetailsModal,
  refreshData,
  studioUsages,
  UserStore,
}) => {
  const user = toJS(UserStore)?.user;
  const canDelete = hasAdminCredentials(user.role);

  const { handleDeleteAppointment } = useAppointmentEvent({
    appointmentId: event.id,
    closeEventDetailsModal,
    refreshData,
  });
  const { locations, instruments, studios } = initialData;

  const teacher = event.teacher;
  const teacherId = teacher?.id || teacher?.uid;

  const appointmentNote = event.note;

  const teachingDaysStr = availableDaysToString(
    teacher?.teacherDays?.availableDays
  );

  const teacherInstrumentsNames = teacher?.instrumentsInfo?.map(
    (el) => el?.instrument?.name
  );
  const teachersInstrumentsStr = instrumentsToString(teacherInstrumentsNames);

  const teacherStudioUsageInCurrentDate = studioUsages?.find((studioUsage) => {
    const isSameTeacher = teacherId === studioUsage.teacher_id;
    const isSameDate =
      moment(event.start).year() === studioUsage.year &&
      moment(event.start).month() + 1 === studioUsage.month &&
      moment(event.start).format("dddd") === studioUsage.weekday;

    return isSameTeacher && isSameDate;
  });
  const studioOnCurrentDate =
    studios[teacherStudioUsageInCurrentDate?.studio_id];
  const studioName = studioOnCurrentDate?.name;

  const locationName = locations[event.locationId]?.name;

  return (
    <div className="p-5 pt-1">
      <ActionButtonsContainer>
        {canDelete && (
          <DeleteOff
            onClick={handleDeleteAppointment}
            style={{ cursor: "pointer" }}
            size={30}
          />
        )}
      </ActionButtonsContainer>

      {!!appointmentNote ? (
        <div className="d-flex mt-4 mb-4">
          <ImageContainer>
            <Notepad width={45} height={45} color="#681e46" />
          </ImageContainer>
          <div className="w-100">
            <CustomTextarea
              marginTop="0px"
              boxShadow="none"
              bgColor="#fff"
              width="100%"
              value={appointmentNote}
              disabled
            ></CustomTextarea>
          </div>
        </div>
      ) : null}
      <div className="d-flex">
        <ImageContainer>
          <ProfileImage src={teacher?.imageUrl || UnknownProfilePhoto} />
        </ImageContainer>
        <div>
          <Header>
            {teacher?.fullName}{" "}
            {`(${teachingDaysStr}) (${teachersInstrumentsStr})`}
          </Header>
          <InfoText>{locationName || "No Location found"}</InfoText>
          <InfoText>{studioName || "No Studio found"}</InfoText>
        </div>
      </div>
    </div>
  );
};

export default injectUserStore(AppointmentEventContent);
