import { useRef, useState, useEffect } from "react";
import "./style.css";

const IconsWrapper = ({
  icon,
  setCurrentActiveButtons,
  iconButtonNameFromSearchParams,
}) => {
  const [isActive, setIsActive] = useState(false);
  const currentButtonIcon = useRef(null);
  const onClickHandler = (e) => {
    e.preventDefault();
    setIsActive((prev) => !prev);
  };
  useEffect(() => {
    if (icon.name === iconButtonNameFromSearchParams) {
      setIsActive(true);
    }
  }, []);
  useEffect(() => {
    if (isActive) {
      currentButtonIcon.current.classList.add("iconWrapperActive");
      setCurrentActiveButtons((prev) => {
        return [...prev, icon.name];
      });
    } else {
      currentButtonIcon.current.classList.remove("iconWrapperActive");
      setCurrentActiveButtons((prev) => {
        return prev.filter((activeIcon) => icon.name !== activeIcon);
      });
    }
  }, [isActive]);
  return (
    <div
      className="iconWrapper"
      onClick={onClickHandler}
      ref={currentButtonIcon}
    >
      {<icon.Icon className="filtersIcon" title={icon.name} />}
    </div>
  );
};

export default IconsWrapper;
