import { useEffect, useState } from "react";
import IconsFilter from "./IconsFilter";
import "./style.css";

const Filters = (props) => {
  const [currentActiveButtons, setCurrentActiveButtons] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const { setRenderedTeachersCard, initialStuff } = props;
  useEffect(() => {
    if (filterInput) {
      const filteredRender = initialStuff.filter((teamMember) => {
        const isSpecializationPresent = teamMember.specialization.filter(
          (iconObj) => {
            return iconObj.name
              .toLowerCase()
              .includes(filterInput.toLowerCase());
          }
        );
        const isNamePresent = teamMember.name
          .toLowerCase()
          .includes(filterInput.toLowerCase());
        return isSpecializationPresent.length || isNamePresent;
      });
      setRenderedTeachersCard(filteredRender);
    } else {
      setRenderedTeachersCard(initialStuff);
    }
  }, [filterInput]);
  return (
    <div className="filtersWrapper">
      <IconsFilter
        {...props}
        setFilterInput={setFilterInput}
        {...{ currentActiveButtons, setCurrentActiveButtons }}
      />
      <input
        type="text"
        className="filterSearch"
        placeholder="input name to search"
        value={filterInput}
        onChange={(e) => setFilterInput(e.target.value)}
      />
    </div>
  );
};

export default Filters;
