import { useSearchParams } from "react-router-dom";
import IconsWrapper from "./IconsWrapper";
import { allIcons } from "../../staffInfo/index.js";
import "./style.css";
import { useEffect } from "react";

const IconsFilter = (props) => {
  const [searchParams] = useSearchParams();
  const currentActiveIconButtonNameFromSearchParams =
    searchParams.get("nameForIconFilter");
  useEffect(() => {
    setCurrentActiveButtons([currentActiveIconButtonNameFromSearchParams]);
  }, []);
  const {
    setRenderedTeachersCard,
    setCurrentActiveButtons,
    currentActiveButtons,
    initialStuff,
  } = props;
  useEffect(() => {
    if (!currentActiveButtons[0]) {
      setRenderedTeachersCard(initialStuff);
    } else {
      setRenderedTeachersCard(
        initialStuff.filter((teacher) => {
          let isTeacherFitsFilters = true;
          currentActiveButtons.forEach((activeButtons) => {
            let isCurrentActiveButtonsIncludes = false;
            for (let i = 0; i < teacher.specialization.length; i++) {
              if (!isTeacherFitsFilters || isCurrentActiveButtonsIncludes) {
                console.log(teacher.name, "break");
                break;
              }
              isCurrentActiveButtonsIncludes =
                teacher.specialization[i].name === activeButtons;
            }
            if (!isCurrentActiveButtonsIncludes) {
              isTeacherFitsFilters = false;
            }
          });
          return isTeacherFitsFilters;
        })
      );
    }
  }, [currentActiveButtons]);
  return (
    <div className="iconsFilterWrapper">
      {allIcons.map((icon) => {
        return (
          <IconsWrapper
            icon={icon}
            key={icon.name}
            setCurrentActiveButtons={setCurrentActiveButtons}
            iconButtonNameFromSearchParams={
              currentActiveIconButtonNameFromSearchParams
            }
          />
        );
      })}
    </div>
  );
};

export default IconsFilter;
