import {
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";

const useFirebaseFns = (searchData, filtersData) => {
  const { searchTerm = "", isSearchEnabled = false } = searchData || {};
  const {
    locationFilter,
    dateFilter,
    areFiltersEnabled = false,
  } = filtersData || {};

  const firebase = useContext(FirebaseContext);

  const getInstruments = async () => {
    try {
      const instrumentsSnap = await getDocs(firebase.instruments());
      const instruments = parseFirebaseDoc(instrumentsSnap.docs);
      return instruments;
    } catch (err) {
      console.log(err);
    }
  };

  const getLocations = async () => {
    try {
      const locationsSnap = await getDocs(firebase.locations());
      const locations = parseFirebaseDoc(locationsSnap.docs);
      return locations;
    } catch (err) {
      console.log(err);
    }
  };

  const getInitialPageQuery = (logsPerPage) => {
    if (isSearchEnabled) {
      return query(
        firebase.absenceLogs(),
        orderBy("studentName", "desc"),
        where("studentName", ">=", searchTerm?.toLowerCase()),
        where("studentName", "<=", searchTerm?.toLowerCase() + "\uf8ff"),
        limit(logsPerPage + 1)
      );
    } else if (areFiltersEnabled) {
      if (locationFilter.isEnabled && dateFilter.isEnabled) {
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where("locationId", "==", locationFilter.value),
          where(
            "createdAt",
            ">=",
            moment(dateFilter.value)
              .set({
                hours: 0,
                minute: 0,
                seconds: 0,
                millisecond: 0,
              })
              .toDate()
          ),
          where(
            "createdAt",
            "<=",
            moment(dateFilter.value)
              .set({
                hours: 23,
                minute: 59,
                seconds: 59,
                millisecond: 0,
              })
              .toDate()
          ),
          limit(logsPerPage + 1)
        );
      } else if (dateFilter.isEnabled) {
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where(
            "createdAt",
            ">=",
            moment(dateFilter.value)
              .set({
                hours: 0,
                minute: 0,
                seconds: 0,
                millisecond: 0,
              })
              .toDate()
          ),
          where(
            "createdAt",
            "<=",
            moment(dateFilter.value)
              .set({
                hours: 23,
                minute: 59,
                seconds: 59,
                millisecond: 0,
              })
              .toDate()
          ),
          limit(logsPerPage + 1)
        );
      } else {
        // only location filter enabled
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where("locationId", "==", locationFilter.value),
          limit(logsPerPage + 1)
        );
      }
    } else {
      // no filters or search
      return query(
        firebase.absenceLogs(),
        orderBy("createdAt", "desc"),
        limit(logsPerPage + 1)
      );
    }
  };

  const getNextPageQuery = (lastLogCursor, logsPerPage) => {
    if (isSearchEnabled) {
      return query(
        firebase.absenceLogs(),
        orderBy("studentName", "desc"),
        where("studentName", ">=", searchTerm?.toLowerCase()),
        where("studentName", "<=", searchTerm?.toLowerCase() + "\uf8ff"),
        startAfter(lastLogCursor),
        limit(logsPerPage + 1)
      );
    } else if (areFiltersEnabled) {
      if (locationFilter.isEnabled && dateFilter.isEnabled) {
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where("locationId", "==", locationFilter.value),
          where(
            "createdAt",
            ">=",
            moment(dateFilter.value)
              .set({
                hours: 0,
                minute: 0,
                seconds: 0,
                millisecond: 0,
              })
              .toDate()
          ),
          where(
            "createdAt",
            "<=",
            moment(dateFilter.value)
              .set({
                hours: 23,
                minute: 59,
                seconds: 59,
                millisecond: 0,
              })
              .toDate()
          ),
          startAfter(lastLogCursor),
          limit(logsPerPage + 1)
        );
      } else if (dateFilter.isEnabled) {
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where(
            "createdAt",
            ">=",
            moment(dateFilter.value)
              .set({
                hours: 0,
                minute: 0,
                seconds: 0,
                millisecond: 0,
              })
              .toDate()
          ),
          where(
            "createdAt",
            "<=",
            moment(dateFilter.value)
              .set({
                hours: 23,
                minute: 59,
                seconds: 59,
                millisecond: 0,
              })
              .toDate()
          ),
          startAfter(lastLogCursor),
          limit(logsPerPage + 1)
        );
      } else {
        // only location filter enabled
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where("locationId", "==", locationFilter.value),
          startAfter(lastLogCursor),
          limit(logsPerPage + 1)
        );
      }
    } else {
      // no filters or search
      return query(
        firebase.absenceLogs(),
        orderBy("createdAt", "desc"),
        startAfter(lastLogCursor),
        limit(logsPerPage + 1)
      );
    }
  };

  const getPrevPageQuery = (firstLogCursor, logsPerPage) => {
    if (isSearchEnabled) {
      return query(
        firebase.absenceLogs(),
        orderBy("studentName", "desc"),
        where("studentName", ">=", searchTerm?.toLowerCase()),
        where("studentName", "<=", searchTerm?.toLowerCase() + "\uf8ff"),
        endBefore(firstLogCursor),
        limitToLast(logsPerPage + 1)
      );
    } else if (areFiltersEnabled) {
      if (locationFilter.isEnabled && dateFilter.isEnabled) {
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where("locationId", "==", locationFilter.value),
          where(
            "createdAt",
            ">=",
            moment(dateFilter.value)
              .set({
                hours: 0,
                minute: 0,
                seconds: 0,
                millisecond: 0,
              })
              .toDate()
          ),
          where(
            "createdAt",
            "<=",
            moment(dateFilter.value)
              .set({
                hours: 23,
                minute: 59,
                seconds: 59,
                millisecond: 0,
              })
              .toDate()
          ),
          endBefore(firstLogCursor),
          limitToLast(logsPerPage + 1)
        );
      } else if (dateFilter.isEnabled) {
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where(
            "createdAt",
            ">=",
            moment(dateFilter.value)
              .set({
                hours: 0,
                minute: 0,
                seconds: 0,
                millisecond: 0,
              })
              .toDate()
          ),
          where(
            "createdAt",
            "<=",
            moment(dateFilter.value)
              .set({
                hours: 23,
                minute: 59,
                seconds: 59,
                millisecond: 0,
              })
              .toDate()
          ),
          endBefore(firstLogCursor),
          limitToLast(logsPerPage + 1)
        );
      } else {
        // only location filter enabled
        return query(
          firebase.absenceLogs(),
          orderBy("createdAt", "desc"),
          where("locationId", "==", locationFilter.value),
          endBefore(firstLogCursor),
          limitToLast(logsPerPage + 1)
        );
      }
    } else {
      // no filters or search
      return query(
        firebase.absenceLogs(),
        orderBy("createdAt", "desc"),
        endBefore(firstLogCursor),
        limitToLast(logsPerPage + 1)
      );
    }
  };
  const getInitialLogs = async (logsPerPage) => {
    try {
      const logsQuery = getInitialPageQuery(logsPerPage);

      const logsSnap = await getDocs(logsQuery);
      // checks if there is next page by checking if the length of the docs has an extra doc thant the per page docs
      const hasNextPage = logsSnap.docs.length === logsPerPage + 1;
      // removes the last extra doc if exists
      const filteredDocs = hasNextPage
        ? logsSnap.docs.slice(0, logsSnap.docs.length - 1)
        : logsSnap.docs;

      const logs = parseFirebaseDoc(filteredDocs);
      console.log({ logs });
      // console.log(
      //   "FIRST PAGE",
      //   logs.map(({ id }) => id)
      // );
      const returnObj = {
        logs,
        hasNextPage,
        lastLogSnap: filteredDocs[filteredDocs.length - 1],
        firstLogSnap: filteredDocs[0],
      };
      return returnObj;
    } catch (err) {
      console.log(err);
    }
  };
  const getNextPageLogs = async (lastLogCursor, logsPerPage) => {
    try {
      const logsQuery = getNextPageQuery(lastLogCursor, logsPerPage);
      const logsSnap = await getDocs(logsQuery);
      const hasNextPage = logsSnap.docs.length === logsPerPage + 1;
      const filteredDocs = hasNextPage
        ? logsSnap.docs.slice(0, logsSnap.docs.length - 1)
        : logsSnap.docs;

      const logs = parseFirebaseDoc(filteredDocs);

      // console.log(
      //   "NEXT PAGE",
      //   logs.map(({ id }) => id)
      // );
      const returnObj = {
        logs,
        hasNextPage,
        lastLogSnap: filteredDocs[filteredDocs.length - 1],
        firstLogSnap: filteredDocs[0],
      };
      return returnObj;
    } catch (err) {
      console.log(err);
    }
  };

  const getPrevPageLogs = async (firstLogCursor, logsPerPage) => {
    try {
      const logsQuery = getPrevPageQuery(firstLogCursor, logsPerPage);

      const logsSnap = await getDocs(logsQuery);
      const hasPrevPage = logsSnap.docs.length === logsPerPage + 1;

      const filteredDocs = hasPrevPage ? logsSnap.docs.slice(1) : logsSnap.docs;
      const logs = parseFirebaseDoc(filteredDocs);

      // console.log(
      //   "PREV PAGE",
      //   logs.map(({ id }) => id)
      // );
      const returnObj = {
        logs,
        hasPrevPage,
        lastLogSnap: filteredDocs[filteredDocs.length - 1],
        firstLogSnap: filteredDocs[0],
      };

      return returnObj;
    } catch (err) {
      console.log(err);
    }
  };
  // // TODO: delete this after testing
  // const getAllLogs = async () => {
  //   try {
  //     const logsQuery = query(
  //       firebase.absenceLogs(),
  //       orderBy("createdAt", "desc")
  //     );
  //     const logsSnap = await getDocs(logsQuery);
  //     const logs = parseFirebaseDoc(logsSnap.docs);
  //     console.log(
  //       "ALL LOGS",
  //       logs.map(({ id }) => id)
  //     );
  //     return logs;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return {
    getInstruments,
    getLocations,
    getInitialLogs,
    getNextPageLogs,
    getPrevPageLogs,
    // getAllLogs,
  };
};

export default useFirebaseFns;
