import { useState } from "react";
import { teachers, admins } from "./staffInfo";
import "./style.css";

import NavMenu from "../common/NavMenu";
import Footer from "../common/Footer";
import BookClassToday from "../common/BookClassToday";
import TeamProfile from "./TeamProfile";
import Filters from "./Filters";

function OurFaculty() {
  const teachersAndAdmins = teachers.concat(admins);
  const [renderTeachersCard, setRenderedTeachersCard] =
    useState(teachersAndAdmins);
  return (
    <main className="our-faculty">
      <NavMenu />
      <div className="our-faculty-container">
        <div className="faculty-container">
          <h2 className="alignCenter">Artis School Team</h2>
          <Filters
            renderTeachersCard={renderTeachersCard}
            setRenderedTeachersCard={setRenderedTeachersCard}
            initialStuff={teachersAndAdmins}
          />
          <div className="teamMemberProfiles">
            {renderTeachersCard.map((teamMember) => {
              return <TeamProfile {...teamMember} key={teamMember.name} />;
            })}
          </div>
          <div>Updated today: {new Date().toDateString()}</div>
        </div>
        <BookClassToday />
        <Footer />
      </div>
    </main>
  );
}

export default OurFaculty;
