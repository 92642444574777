import moment from "moment";
import React from "react";
import { Container } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import { absenceTypes } from "src/constants/absenceTypes";
import { logActionTypes, logTypes } from "src/constants/logTypes";
import {
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";

const LogsTable = ({ logsData }) => {
  const { initialData, loadingInitialData, logsListData, loadingLogsList } =
    logsData || {};
  const { instruments = [] } = initialData || {};

  const {
    logs = [],
    hasNextPage = false,
    hasPrevPage = false,
  } = logsListData || {};

  const parseDate = (date) => {
    return moment(date).format("MM-DD-YYYY hh:mm A");
  };

  // const parseTime = (date) => {
  //   return moment(date).format("hh:mm A");
  // };

  if (loadingInitialData || loadingLogsList) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <Container fluid>
      <CustomTableContainer>
        <CustomTable>
          <thead>
            <TableRow>
              <th>
                <div>Created At</div>
              </th>
              <th>
                <div>Type</div>
              </th>
              <th>
                <div>Absence Type</div>
              </th>
              <th>
                <div>Student Name</div>
              </th>
              <th>
                <div>Absence/Make Up Date</div>
              </th>

              <th>
                <div>Request By</div>
              </th>
              <th>
                <div>Updated By</div>
              </th>
              <th>
                <div>Teacher</div>
              </th>
              {/* <th>
                <div>Action Type</div>
              </th> */}
            </TableRow>
          </thead>
          <tbody>
            {logs.map((log, i) => {
              const {
                absenceType,
                action,
                createdAt,
                date,
                docId,
                instrumentId,
                locationId,
                requestById,
                requestByName,
                requestByRole,
                studentId,
                studentName,
                teacherId,
                teacherName,
                type,
                updatedByName,
              } = log;

              // preparing table values
              const instrumentName = instruments?.find(
                ({ id }) => id === instrumentId
              )?.name;
              const absenceTypeAbbr = Object.values(absenceTypes).find(
                ({ code }) => code === absenceType
              )?.abbr;
              const logTypeName = Object.values(logTypes).find(
                ({ code }) => code === type
              )?.name;
              const actionTypeName = Object.values(logActionTypes).find(
                ({ code }) => code === action
              )?.name;

              const formattedDate = date
                ? parseDate(date?.toDate())
                : undefined;

              const formattedCreatedAt = createdAt
                ? parseDate(createdAt?.toDate())
                : undefined;

              const isEvenRow = i % 2 === 0;
              return (
                <TableRow
                  style={{ ...(isEvenRow && { background: "#f9f9f9" }) }}
                  key={i}
                >
                  <td>{formattedCreatedAt || "N/A"}</td>
                  <td>{`${actionTypeName}: ${logTypeName}` || "N/A"}</td>
                  <td>{absenceTypeAbbr || "N/A"}</td>
                  <td>{studentName || "N/A"}</td>
                  <td>{formattedDate || "N/A"}</td>
                  <td>{requestByName || "N/A"}</td>
                  <td>{updatedByName || "N/A"}</td>

                  <td>{`${teacherName} | ${instrumentName}` || "N/A"}</td>
                  {/* <td>{actionTypeName || "N/A"}</td> */}
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
    </Container>
  );
};

export default LogsTable;
